.buttonSearch {
  width: 30%;
}

.barSearch {
  width: 70%;
}

.border-marker {
  display: flex;
  width: fit-content;
  border-radius: 50%;
  padding: 0px;
}