.comment-area {
  background-color: #ffffff;
  padding: 1rem;
  width: fit-content;
  border-radius: 3px;
  margin-left: 20px;
  border: 1px solid #d8d8d8;
}

.comment-area p {
  margin: 0;
}

.comment-holder {
  background-color: #f3f3f3;
  padding: 1rem;
  border-radius: 15px;
}

.date {
  font-size: 11px;
}

.user-name {
  color: var(--blue);
}
