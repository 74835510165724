.imageCompany {
  margin-left: auto;
  max-width: 100%;
  max-height: 64px;
}

.title-container {
  display: flex !important;
}

.title-chart {
  margin-top: 10px !important;
  font-family: Arial !important;
  font-size: 14px !important;
  font-weight: bold !important;
  line-height: 1.5 !important;
  margin: auto !important;
  color: var(--primary-color) !important;
}

.flex-container {
  display: flex !important;
  height: 100% !important;
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
}

.flex-container-2 {
  display: flex !important;
  justify-content: flex-start !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  width: 100%;
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: auto;
  margin: 0px 20px;
}

.evol {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.thead > th {
  border-bottom: solid 1px white;
  color: white;
  background-color: #4a4c65 !important;
}
.thead > th:nth-child(1) {
  border-bottom-left-radius: 5px;
}
.thead > th:nth-last-child(1) {
  border-bottom-right-radius: 5px;
}
