* {
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
}

th > div > span {
  width: 100%;
}

.holiday * {
  background-color: yellow !important;
}

.for-print {
  width: 950px;
}
.for-print div {
  box-shadow: unset !important;
  padding-top: 0px;
  border-radius: 15px;
  padding-left: 3px !important;
}
.for-print h2 {
  border-top: 2px solid !important;
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
  margin-top: 0px;
  margin-left: 20px !important;
}

.for-print-color {
  background-color: transparent !important;
}
.for-print td {
  background-color: var(--white) !important;
  border: solid 1px var(--thirty-color) !important;
}
.page-break {
  page-break-after: always;
}
.height-print {
  height: 40px !important;
}

.min-height-0{
  min-height: 0;
}

.container-file-viewer {
  z-index: 100;
}

.container-file-viewer > div {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.container-file-viewer > div > div {
  display: flex;
}

.col_full_height {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.col_full_height > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.col_full_height > div > div:last-child {
  margin: auto 0;
}

.weekend-event,
.holiday-event {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.weekend-event {
  z-index: 100 !important;
}

.holiday-event {
  z-index: 90 !important;
}

.remove-relative {
  position: unset !important;
}
