#body {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.fullScreen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.screen {
  width: 100%;
  height: 100%;
}

.mb-20 {
  margin-bottom: 200px;
}
.carrousel {
  max-width: 100%;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 50px;
  margin-top: 30px;
}
