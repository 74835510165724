.min-tab-size-xs {
  min-height: 200px;

}

.min-tab-size-md {
  min-height: 200px;

}

/* SUMMARY */

.performance .summary-container .img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.performance .summary-container img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.performance .summary-container H2 {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--primary-color);
}
.performance .summary-container-name {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #5c5c5c;
  margin: 0;
}
.performance .btn-gem {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 22px;
  border-radius: 5px;
  background-color: var(--primary-color);
}
.performance .btn-gem p {
  width: 45px;
  height: 13px;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--white);
  margin-right: 5px;
}
.performance .btn-gem svg {
  width: 14px;
  height: 11.9px;
  color: var(--white);
}

/* Agencies checkbox */
.checkBox {
  display: flex;
  justify-content: flex-start;
  position: relative
}

.checkBoxFont span{
  width: 2em;
  height: 2em;
}
.checkBoxFont span:after{
  left: 40% !important ;
  top:  -20% !important ;
  border-width: 0px 2px 2px 0px !important ;
}
.checkBoxFont label{
  font-size: 10px;
}